import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import FetchData from "../fetch-api/Apifetch";
import { LIST_ACTIVITY, LIST_REPORT } from "./ApiRoute";


export const handleImageError = (event) => {
  event.target.src = `/images/imageplaceholder.jpeg`; // path to your default image
};
export const handleProImageError = (event) => {
  event.target.src = `/images/images.png`; // path to your default image
};

export const TimeCalculator = ({ application, onTimeCalculated }) => {
  // Function to convert time format to seconds
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Function to convert seconds to time format
  const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (application.Total_Usage) {
      // Convert Total_Usage time to seconds
      const usageInSeconds = timeToSeconds(application.Total_Usage);

      // Convert seconds to time format
      const formattedTime = secondsToTime(usageInSeconds);

      // Call the parent function with the calculated time
      onTimeCalculated(formattedTime, usageInSeconds);
    }
  }, [application, onTimeCalculated]);

  return null; // No UI needed as we only calculate and return the value
};

export const ActivityTrackingApi = async () => {
  // get user data through localstorage and set it 
  const user = 1;
  try {
    const res = await FetchData(LIST_ACTIVITY + `?page=1&limit=7`, 'GET', null, true, false);
    console.log(res);
  } catch (err) {
    console.log(err);
  }


}

export const getStatus = (ID) => {
  const statusMap = {
    "0": "In Progress",
    "1": "Cancelled",
    "2": "Completed",
    "3": "Yet to start"
  };

  return statusMap[ID] || "Unknown Status";
};

export const FormatTime = (input) => {
  // Remove non-digit characters
  const cleanedInput = input.replace(/\D/g, '');

  // Extract hours, minutes, and seconds from cleanedInput
  const hours = cleanedInput.substring(0, 2);
  const minutes = cleanedInput.substring(2, 4);
  const seconds = cleanedInput.substring(4, 6);

  let formattedTime = '';

  if (hours) {
    formattedTime = hours;
  }

  if (minutes) {
    formattedTime += (hours ? ':' : '') + minutes;
  }

  if (seconds) {
    formattedTime += (minutes ? ':' : '') + seconds;
  }

  return formattedTime;
};

export const Listicons = () => {

  const location = useLocation()


  return (

    <>
      <a href='/application-track' className={`${location.pathname == '/application-track' ? '' : ''} hoveringpad`}><img src={location.pathname == '/application-track' ? '/images/activityactive.png' : '/images/icons/activity.png'} style={{ width: '25px', height: '23px' }} /></a>
      <a href='/Url-track' className={`${location.pathname == '/Url-track' ? '' : ''} hoveringpad`}><img src={location.pathname == '/Url-track' ? '/images/linkactive.png' : '/images/icons/urls.png'} style={{ width: '25px', height: '23px' }} /></a>
      <a href='/screen-shots' className={`${location.pathname == '/screen-shots' ? '' : ''} hoveringpad`}><img src={location.pathname == '/screen-shots' ? '/images/landscapeactive.png' : '/images/icons/capture.png'} style={{ width: '25px', height: '23px' }} /></a>
      <a href='/keystroke' className={`${location.pathname == '/keystroke' ? '' : ''} hoveringpad`}><img src={location.pathname == '/keystroke' ? '/images/mouseactive.png' : '/images/icons/keyboard.png'} style={{ width: '25px', height: '23px' }} /></a>
      <a href='/locations' className={`${location.pathname == '/locations' ? '' : ''} hoveringpad`}><img src={location.pathname == '/locations' ? '/images/locationactive.png' : '/images/icons/location.png'} style={{ width: '25px', height: '23px' }} /></a>
    </>
  )
}

export const calculateDaysBetween = (fromDate, toDate) => {
  if (!fromDate || !toDate) return ''; // If any date is missing, return empty string

  const from = new Date(fromDate?.split(/[\sT]/)[0]); // Convert to Date object
  const to = new Date(toDate?.split(/[\sT]/)[0]); // Convert to Date object

  // Calculate the time difference in milliseconds
  const timeDifference = to - from;

  // Convert the time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference;
};

export const RemainingDays = (date) => {
  const targetDate = new Date(date);

  const currentDate = new Date();

  const timeDifference = targetDate.getTime() - currentDate.getTime();

  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  console.log("daysRemaining",daysRemaining);
  return daysRemaining;
}

export const NOData = () => {
return(
<div className="container-fluid pb-2 mt-3">
  <div
    className="container-fluid w-100 d-flex justify-content-center align-items-center"
    style={{ height: 200 }}
  >
    <div className="w-100 d-flex align-items-center row-gap-1 flex-column justify-content-center">
      <span className="fontsubtitle font-weight-bold text-dark1">
        No Data Found?
      </span>
      <a>
        <span
          className="fontsubtitle font-weight-1 text-connect1"
          role="button"
        >
          Try later Again
        </span>
      </a>
    </div>
  </div>
</div>

)


}
