import React from 'react'

const CommonLoader = () => {
  return (
    <div style={{ background: '#00000054', position: "fixed", top: 0, left: 0, width: "100vw", zIndex: 10000, overflow: "hidden" }}>
      <div className='d-flex justify-content-center align-items-center w-100' style={{ height: '100vh' }}>
        <img src='/images/browsers/output-onlinegiftools.gif' />
      </div>
    </div>
  )
}

export default CommonLoader