import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { english, arabic } from './Language'; // Import your translations

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: english,
      },
      ar: {
        translation: arabic,
      },
    },
    lng: localStorage.getItem('lang') || 'en', // Default to 'en' if no language is stored
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // Not needed for React
    },
  });

export default i18n;
