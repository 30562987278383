import React, { useEffect, useState } from 'react';
import FetchData from '../fetch-api/Apifetch';
import { BASE_URL, LIST_REPORT } from '../utils/ApiRoute';
import { handleProImageError } from '../utils/Function';
import Swal from 'sweetalert2';
import i18n from '../utils/i18n';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';

const Header = ({ toggleSidebar, search, setSearch,filters }) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem('token')));
  const [EmpReport, setEmpReport] = useState([]);
  const initialLang = localStorage.getItem('lang') || 'en'; // Default to 'en' if not set
  const [lang, setLang] = useState(initialLang);
  const { t, i18n } = useTranslation();
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [UserId, setUserId] = useState(user.emp_id);
  const [filter, setfilter] = useState(user.emp_id);

  useEffect(() => {
    ListReportingEmp();
  }, []);

  // Effect to handle future changes to the language
  useEffect(() => {
    document.documentElement.setAttribute('lang', lang); // Optionally, set the 'lang' attribute
    document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr'; // Apply the direction to the body
  }, [lang]);

  const toggleLanguage = () => {
    const newLang = lang === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLang); // Toggle between 'en' and 'ar'
    setLang(newLang); // Update the language state
    localStorage.setItem('lang', newLang); // Store the new language in localStorage
    window.location.reload()
  };

  const handleIconClick = () => {
    if (window.innerWidth < 768) {
      // toggleDataAttribute();
    }
  };

  const ListReportingEmp = async () => {
    try {
      const res = await FetchData(LIST_REPORT + `?emp_id=${user.emp_id}`, 'GET', null, true, false);
      console.log(res.data);
      setEmpReport(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleuserchange = async (e) => {
    filters(e);
    setfilter(e);
    setUserId(e);
  }

  const handleLogout = async () => {
      // localStorage.removeItem('user');
      // localStorage.removeItem('token');
      // localStorage.removeItem('AuthToken');
      localStorage.clear()
      window.location.href = '/login';
    }

  return (
    <header className="app-header">
      <style>
        {`
    .swal2-popup {
      font-size: 1rem; /* Adjust font size of the popup */
    }

    .custom-confirm-button,
    .custom-cancel-button {
      border: none;
      border-radius: 0.375rem; /* Adjust border radius */
      font-size: 1rem; /* Font size of the button text */
      font-weight: bold; /* Font weight */
      padding: 0.75rem 1.25rem; /* Button padding */
      margin: 0.25rem; /* Space between buttons */
      cursor: pointer; /* Pointer cursor */
    }

    .custom-confirm-button {
      background-color: #433EBE; /* Custom color for "Yes" button */
      color: #fff; /* Text color */
    }

    .custom-confirm-button:hover {
      background-color: #433EBE; /* Darker shade for hover effect */
    }

    .custom-cancel-button {
      background-color: #6c757d; /* Custom color for "No" button */
      color: #fff; /* Text color */
    }

    .custom-cancel-button:hover {
      background-color: #5a6268; /* Darker shade for hover effect */
    }

    .swal2-styled.swal2-confirm {
      background-color: #433EBE !important; /* Ensure color is applied */
    }

    .swal2-styled.swal2-cancel {
      background-color: #6c757d !important; /* Ensure color is applied */
    }
  `}
      </style>


      <nav className="main-header" aria-label="Global">
        <div className="main-header-container !px-[0.85rem]">
          <div className="header-content-left">
            {/* Start::header-element */}
            <div className="header-element">
              <div className="horizontal-logo d-none">
                <a href="#" className="header-logo">
                  <img
                    src='./public/assets'
                    alt="logo"
                    className="desktop-logo"
                  />
                  <img
                    src="/assets/images/brand-logos/applogo.png"
                    alt="logo"
                    className="toggle-logo"
                    width={50}
                    height={50}
                  />
                  <img
                    src="../assets/images/brand-logos/desktop-dark.png"
                    alt="logo"
                    className="desktop-dark"
                  />
                  <img
                    src="../assets/images/brand-logos/toggle-dark.png"
                    alt="logo"
                    className="toggle-dark"
                  />
                  <img
                    src="../assets/images/brand-logos/desktop-white.png"
                    alt="logo"
                    className="desktop-white"
                  />
                </a>
              </div>
            </div>

            {/* End::header-element */}
            <div className="header-element !items-center">
              {/* Start::header-link */}
              <a
                aria-label="Hide Sidebar"
                className="sidemenu-toggle animated-arrow header-link hor-toggle horizontal-navtoggle inline-flex items-center"
                href="javascript:void(0);"
              >
                <i className="header-icon fe fe-align-left"
                  // onClick={()=>{handleIconClick()}}  
                  onClick={toggleSidebar}
                />
              </a>
              <div className="main-header-center hidden lg:block">
                <input
                  className="form-control placeholder:!text-headerprimecolor placeholder:opacity-70 placeholder:font-thin placeholder:text-sm"
                  placeholder={t('SearchForAnything')}
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button className="btn">
                  <i className="ri ri-search-2-line hidden md:block opacity-[0.5]" />
                </button>
              </div>
              {/* End::header-link */}
            </div>
          </div>

          <div className="header-content-right align-items-center gap-3">
                {/* <div className='mr-2 d-flex btn-drop-down'>
                  <div>
                    <img src='/images/icons/man (1).png' width={15} height={15} />
                  </div>

                  <div className="dropdown">
                    <input
    className="form-control border-0 py-0"
    style={{height:"100%"}}
    list="empList"
    placeholder={UserId ? EmpReport.find(item => item.emp_id === filter)?.name : 'Select an option'}
    onChange={(e) => {
      const selectedItem = EmpReport.find(item => item.name === e.target.value);
      if (selectedItem) {
        handleuserchange(selectedItem.emp_id);
      }
    }}
  />
  <ul className="dropdown-menu">
                      <datalist id="empList">
    {EmpReport.map(item => (
      <option key={item.emp_id} value={item.name} />
    ))}
  </datalist>
                    </ul>
                  </div>
                </div> */}
            <div className="header-element items-center mr-2">
              <select
                className="form-select form-select-sm cursor-pointer border-0 text-dark dark:text-light"
                onChange={toggleLanguage}
                value={lang}
              >
                <option value="en">English</option>
                <option value="ar">عربي</option>
              </select>
            </div>

            <a onClick={()=>setIsConfirmation(true)} role='button'>
              <img
                src={ BASE_URL + user?.emp_image }
                style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                onError={handleProImageError}
                alt="User Profile"
              />
            </a>
            <span><strong>{t('welcome')} {user?.name}</strong></span>

            <img className='d-none d-md-block d-lg-block' 
            // src='/images/logos/Toptecno Logo-1.png' 
            src='/assets/images/brand-logos/applogo.png'
            // style={{ width: '95px', height: '56px' }} 
            style={{ width: '56px', height: '56px' }} 
            />
          </div>
        </div>
      </nav>
      <CommonModal
        icon={"/images/icons/logout.png"}
        title={"Are you sure"}
        desc={`${t('logoutalert')}`}
        submitText={`${t('yeslogout')}`}
        submitAction={() => { handleLogout() }}
        setShowModal={()=>setIsConfirmation(false)}
        showModal={isConfirmation}
      />
    </header>
  );
};

export default Header;
