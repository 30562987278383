import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const Shimmer = () => {
  return (
    <div className='container-fluid w-100 shadow-sm' style={{borderRadius:10}}>
      <div className="table-responsive country-table">
            <table className="table table-auto ti-custom-table table-striped table-bordered mb-0 text-nowrap w-full !border-defaultborder dark:!border-defaultborder/10">
              <thead>
                <tr>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                  <th className="wd-lg-25p text-start !text-xs">        <Skeleton /></th>
                </tr>
              </thead>
              <tbody>
                {/* {Attendence.map(item=>( */}
                
               {Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                </tr>
                ))}
                {/* ))} */}
              </tbody>
            </table>
          </div>
      {/* <div className='w-100 py-2'>
        <Skeleton />
      </div>
      <div className='w-100 py-2'>
        <Skeleton />
      </div>
      <div className='w-100 py-2'>
        <Skeleton />
      </div> */}
      
    </div>
  )
}

